import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { Alert } from "@mui/material";
import {
  loginUserUsingToken,
  resetUserPasswordUsingAccessToken,
} from "../supabase/supabase";
import Token from "../supabase/Token";
import PageHeader from "../Components/PageHeader";
import { Colors } from "../Colors";

export default function ResetPassword() {
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [passwordValid, setPasswordValid] = useState(true);
  const [newPassword, setNewPassword] = useState<string>("");
  const [passwordResetMessage, setPasswordResetMessage] = useState<{
    message: string;
    success: boolean;
  }>({ message: "", success: false });

  useEffect(() => {
    if (passwordsMatch && passwordValid && newPassword != "") {
      const token = new Token(window.location.search);
      loginUserUsingToken(token).then(() => {
        resetUserPasswordUsingAccessToken(token, newPassword)
          .then(({ data: { user } }) => {
            setPasswordResetMessage({
              message: `Password has been successfully updated for ${user?.email}!`,
              success: true,
            });
          })
          .catch((error) =>
            setPasswordResetMessage({
              message: "Error updating password:  " + error.message,
              success: false,
            })
          );
      });
    }
  }, [newPassword]);

  const renderPasswordReset = () => {
    setTimeout(() => {
      setPasswordResetMessage({ message: "", success: false });
    }, 10000);
    if (passwordResetMessage.message != "") {
      return passwordResetMessage.success ? (
        <Alert severity="success" style={{ width: "100%" }}>
          {passwordResetMessage.message}
        </Alert>
      ) : (
        <Alert severity="error" style={{ width: "100%" }}>
          {passwordResetMessage.message}
        </Alert>
      );
    }
  };

  const checkPasswordStrength = (password: string) => {
    setPasswordsMatch(true);
    const minLength = 8; // Minimum length requirement
    if (password.length >= minLength) {
      setPasswordValid(true);
    } else {
      setPasswordValid(false);
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    let confirmPassword = data.get("confirm-password");
    let password = data.get("password");

    if (confirmPassword != password) {
      setPasswordsMatch(false);
    } else {
      setNewPassword(password as string);
    }
  };

  return (
    <>
      <PageHeader text={"Password Reset"} />
      <Box
        component="form"
        noValidate
        onSubmit={handleSubmit}
        sx={{ mt: 1, mx: 3 }}
      >
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          onChange={(e) => checkPasswordStrength(e.target.value)}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="confirm-password"
          label="Confirm Password"
          type="password"
          id="confirm-password"
          autoComplete="current-password"
        />
        <Button
          type="submit"
          fullWidth
          style={{ backgroundColor: Colors.BeerOrange }}
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Submit Password Change
        </Button>
      </Box>
      {!passwordValid ? (
        <Alert severity="error" style={{ width: "100%" }}>
          Passwords must be atleast 8 characters long
        </Alert>
      ) : null}
      {!passwordsMatch ? (
        <Alert severity="error" style={{ width: "100%" }}>
          Passwords Don't Match!
        </Alert>
      ) : null}
      {renderPasswordReset()}
    </>
  );
}
