class Token {
  accessToken: string;
  expiresIn: string;
  refreshToken: string;
  tokenType: string;
  type: string;
  error: string;

  constructor(url: string) {
    const urlParams = new URLSearchParams(url);
    this.accessToken = urlParams.get("access_token") as string;
    this.expiresIn = urlParams.get("expires_in") as string;
    this.refreshToken = urlParams.get("refresh_token") as string;
    this.tokenType = urlParams.get("token_type") as string;
    this.type = urlParams.get("type") as string;
    this.error = urlParams.get("error") as string;
  }

  toString() {
    return `accessToken: ${this.accessToken}\n\r\n\rexpiresIn: ${this.expiresIn}\n\r\n\rrefreshToken: ${this.refreshToken}\n\r\n\rtokenType: ${this.tokenType}\n\r\n\rtype: ${this.type}`;
  }
}

export default Token;
