import Typography from "@mui/material/Typography";
import React from "react";
import PageHeader from "../Components/PageHeader";
import { Box } from "@mui/material";

export default function EmailVerifiedPage() {
  return (
    <>
      <PageHeader text={"Welcome to Hoppy Days!"} />
      <Box sx={{ m: 6, textAlign: "center" }}>
        <Typography variant="body1">Your Email Has Been Confirmed!</Typography>
      </Box>
    </>
  );
}
