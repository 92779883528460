import { Box, CssBaseline, Grid, Paper, Typography } from "@mui/material";
import React, { useEffect } from "react";

const AppStore = () => {
  useEffect(() => {
    const openAppStore = () => {
      const userAgent = navigator.userAgent || navigator.vendor;

      if (/android/i.test(userAgent)) {
        //TODO update this with our google play store link when it is in prod, right now it is the internal tester version
        window.location.href =
          "https://play.google.com/store/apps/details?id=com.ketri.hoppydays";
      } else if (/iPad|iPhone|iPod/i.test(userAgent)) {
        //TODO update this with our apple app store link when it is in prod
        window.location.href =
          "https://apps.apple.com/us/app/hoppy-days/id6449238548";
      } else {
        window.location.href = "https://hoppydaysbeer.com";
      }
    };

    openAppStore();
  }, []);

  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage:
            "url(https://source.unsplash.com/random/?Humulus-lupulus)",
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant="body1">
            Introducing Hoppy Days - the ultimate solution for friends looking
            to beat the recession blues together. Nothing brings people together
            like sharing a cold, refreshing beverage at your favorite boutique
            brewery or ciderhouse while participating in fun events. Our goal at
            hoppy days is to make people aware of local events happening at
            their favorite drinking spots. We compile local events for
            breweries, taprooms, and cider houses, and put them all into one
            convient location. By using our app you will also gain access to
            exclusive deals offered by the breweries. Don't miss out on the
            opportunity to enjoy fun events with your friends!
          </Typography>
          <div style={{ flexDirection: "column", textAlign: "center" }}>
            <div>
              <a href="https://play.google.com/store/apps/details?id=com.ketri.hoppydays&hl=en_US&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                <img
                  style={{ width: "35%" }}
                  alt="Get it on Google Play"
                  src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                />
              </a>
            </div>
            <div>
              <a href="https://apps.apple.com/us/app/hoppy-days/id6449238548?itsct=apps_box_badge&amp;itscg=30200">
                <img
                  style={{ width: "30%" }}
                  src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1687737600"
                  alt="Download on the App Store"
                />
              </a>
            </div>
          </div>
        </Box>
      </Grid>
    </Grid>
  );
};

export default AppStore;
