import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import PageHeader from "../Components/PageHeader";
import { Alert, Box, Button, TextField } from "@mui/material";
import { Colors } from "../Colors";
import { supabase } from "../supabase/supabase";
import { PostgrestSingleResponse } from "@supabase/supabase-js";

class Brewery {
  name: string = "";
}

export default function HomePage() {
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [sucessMessage, setSucessMessage] = useState("");
  const [breweries, setBreweries] = useState<Brewery[]>([]);

  useEffect(() => {
    getBreweriesManagingInOurApp();
  }, []);

  const getBreweriesManagingInOurApp = async () => {
    const { data: breweries, error }: PostgrestSingleResponse<Brewery[]> =
      (await supabase
        .from("breweries")
        .select("name")
        .not("owner_id", "is", null)
        .neq("owner_id", "e374903f-7dc4-43f8-9dd7-6b5c6de90a1e")
        .neq(
          "owner_id",
          "ab1a116b-22c3-4134-a65d-32ff14b10341"
        )) as PostgrestSingleResponse<Brewery[]>;
    !!breweries && setBreweries(breweries);
    console.log(JSON.stringify(breweries));
  };

  return (
    <>
      <PageHeader text={"Welcome to Hoppy Days!"} />
      <Box sx={{ m: 3 }}>
        <Typography variant="body1">
          Introducing Hoppy Days - the ultimate solution for friends looking to
          beat the recession blues together. Nothing brings people together like
          sharing a cold, refreshing beverage at your favorite boutique brewery
          or ciderhouse while participating in fun events. Our goal at hoppy
          days is to make people aware of local events happening at their
          favorite drinking spots. We compile local events for breweries,
          taprooms, and cider houses, and put them all into one convient
          location. By using our app you will also gain access to exclusive
          deals offered by the breweries. Don't miss out on the opportunity to
          enjoy fun events with your friends!
        </Typography>
        <div style={{ flexDirection: "column", textAlign: "center" }}>
          <div>
            <a href="https://play.google.com/store/apps/details?id=com.ketri.hoppydays&hl=en_US&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
              <img
                style={{ width: "35%" }}
                alt="Get it on Google Play"
                src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
              />
            </a>
          </div>
          <div>
            <a href="https://apps.apple.com/us/app/hoppy-days/id6449238548?itsct=apps_box_badge&amp;itscg=30200">
              <img
                // style="border-radius: 13px; width: 250px; height: 83px;"
                style={{ width: "30%" }}
                src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1687737600"
                alt="Download on the App Store"
              />
            </a>
          </div>
        </div>
        {/* <Typography sx={{ textAlign: "center", marginTop: 3 }}>
          <a href="https://play.google.com/store/apps/details?id=com.ketri.hoppydays&hl=en_US&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
            <img
              style={{ width: "35%" }}
              alt="Get it on Google Play"
              src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
            />
          </a>
        </Typography>
        <Typography sx={{ textAlign: "center", marginTop: 3 }}>
          <a href="https://apps.apple.com/us/app/hoppy-days/id6449238548?itsct=apps_box_badge&amp;itscg=30200">
            <img
              // style="border-radius: 13px; width: 250px; height: 83px;"
              style={{ width: "32%" }}
              src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1687737600"
              alt="Download on the App Store"
            />
          </a>
        </Typography> */}
        <Typography
          variant="h4"
          sx={{ textAlign: "center", marginTop: 3, fontWeight: "bold" }}
        >
          Breweries
        </Typography>
        {breweries.map((brewery) => (
          <Typography
            variant="body1"
            sx={{ textAlign: "center", marginTop: 1, fontWeight: "bold" }}
          >
            {brewery.name}
          </Typography>
        ))}
      </Box>
    </>
  );
}
