import { UserResponse, createClient } from '@supabase/supabase-js';
import Token from './Token';

const supabaseUrl = 'https://ndvbuxahptejwcqylftp.supabase.co'
const supabaseAnonKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im5kdmJ1eGFocHRlandjcXlsZnRwIiwicm9sZSI6ImFub24iLCJpYXQiOjE2NzgyMzIzMTEsImV4cCI6MTk5MzgwODMxMX0.71zYVGBSiTvQ6WjT_bd9dPL0dDT7-OALLfQ2Z3olvSk'

export function updateSession(token: Token) {
  supabase.auth.setSession({
    access_token: token.accessToken,
    refresh_token: token.refreshToken
  })
}

export async function loginUserUsingToken(token: Token) {
  return await supabase.auth.getUser(token.accessToken)
}

export async function resetUserPasswordUsingAccessToken(token: Token, password: string): Promise<UserResponse> {
  return await supabase.auth.updateUser({ password: password })
}

export const supabase = createClient(supabaseUrl, supabaseAnonKey)
