import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';

import React from 'react';
import { Colors } from '../Colors';
type _react = typeof React

export default function PageHeader({ text }: { text: string }) {
    return (
        <Box sx={{ bgcolor: Colors.BeerOrange, p: 2, width: '100%', textAlign: 'center' }}>
            <img
                src={require('../Assets/adaptive-icon.png')}
                alt="Adaptive Icon"
                style={{ width: '20%', height: '20%' }}
            />
            <Typography variant="h4">{text}</Typography>
        </Box>
    );
}