import React from "react";
import Token from "./supabase/Token";
import {
  Box,
  CssBaseline,
  Grid,
  Paper,
  Typography,
  createTheme,
} from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import ResetPassword from "./Pages/ResetPassword";
import EmailVerifiedPage from "./Pages/EmailVerifiedPage";
import LinkExpired from "./Pages/LinkExpired";
import HomePage from "./Pages/HomePage";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import TermsOfService from "./Pages/TermsOfService";
import AppStore from "./Pages/AppStore";

function App() {
  var token = new Token(window.location.hash.substring(1));

  const theme = createTheme();

  function DrawerContent() {
    if (token.error !== null) {
      return <LinkExpired />;
    }
    if (token.type === "recovery") {
      return <ResetPassword />;
    } else if (token.type === "signup") {
      return <EmailVerifiedPage />;
    }

    return <HomePage />;
    // return <BrewerySignIn />;
  }

  function Copyright(props: any) {
    return (
      <Typography
        variant="body2"
        color="text.secondary"
        align="center"
        {...props}
      >
        {"Copyright © Hoppy days " + new Date().getFullYear() + "."}
      </Typography>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Router basename={"/"}>
        <Routes>
          <Route
            path="/"
            element={
              <Grid container component="main" sx={{ height: "100vh" }}>
                <CssBaseline />
                <Grid
                  item
                  xs={false}
                  sm={4}
                  md={7}
                  sx={{
                    backgroundImage:
                      "url(https://source.unsplash.com/random/?Humulus-lupulus)",
                    backgroundRepeat: "no-repeat",
                    backgroundColor: (t) =>
                      t.palette.mode === "light"
                        ? t.palette.grey[50]
                        : t.palette.grey[900],
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                />
                <Grid
                  item
                  xs={12}
                  sm={8}
                  md={5}
                  component={Paper}
                  elevation={6}
                  square
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <DrawerContent />
                    {/* <div style={{
                            position: 'fixed',
                            bottom: 0,
                            width: '100%',
                            paddingBottom: '2vh',
                            
                        }}>
                            <Copyright />
                        </div> */}
                  </Box>
                </Grid>
              </Grid>
            }
          />
          <Route path="/TOS" element={<TermsOfService />} />
          <Route path="/AppStore" element={<AppStore />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
