import Typography from "@mui/material/Typography";
import React from "react";
import PageHeader from "../Components/PageHeader";
import { Box } from "@mui/material";

export default function LinkExpired() {
  return (
    <>
      <PageHeader text={"Oops!"} />
      <Box sx={{ m: 6, textAlign: "center" }}>
        <Typography variant="body1">
          It looks like your request has timed out, please try to request
          another link and try again 😀
        </Typography>
      </Box>
    </>
  );
}
